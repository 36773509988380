import React from 'react';

import { StaticImage } from "gatsby-plugin-image";

import FancyBackground from '../components/FancyBackground';

const WhenSection = (props) => {
    return (
        <>
            <div className={'min-width-50-pc'}>
                <h2 style={{marginTop:'3rem'}}>Quand consulter ?</h2>
                <p>Nous avons parfois le sentiment que certains de nos comportements nous échappent.
                    Ils sont sources de mal-être et nous n'en comprenons pas toujours l'origine.
                    Mon rôle est de vous aider à y voir plus clair et de trouver la voie du changement
                    ou de l'acceptation.
                </p>
            </div>
            <div className={'min-width-50-pc'} style={{position:`relative`}}>
                <FancyBackground color="#BA00BD" size={.8}>
                    <StaticImage src="../images/cactus.png" alt=""  height={1500}/>
                </FancyBackground>
            </div>
        </>
    )
}

export default WhenSection