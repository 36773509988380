import React, {useEffect, useState} from "react";
import { useMediaQuery } from 'react-responsive';

const textContainerStyle = {
    display:`flex`,
    flexDirection: `row`,
    flexWrap:`wrap`,
    marginLeft: `1rem`,
    width: `100%`,
    justifyContent: "flex-start",
}

const iconContainerStyle = {
    display: `flex`, 
    flexDirection:`column`,
    justifyContent: 'center',
    color:`#094D92`,
    background: `#FFFFFF`,
    borderRadius: `2.5rem 0 0 2.5rem`,
    height:`100%`
}

const iconNeuromorphicStyle = {
    borderRadius:`100%`,
    padding:`1rem`,
    margin: `1rem`
}

const backgroundStyle = {
    position:`absolute`,
    left: `0`,
    top:`0`,
    width:`100%`,
    height: `100%`,
    background: `white`,
}

const ContactButton = (props) => {
    const bp1024 = useMediaQuery({ query: '(min-width: 769px) and (max-width: 1024px)' })
    const bp768 = useMediaQuery({ query: '(min-width: 577px)  and (max-width: 768px)' })
    const bp576 = useMediaQuery({ query: '(max-width: 576px)' })

    const [focused, setFocused] = useState(false)

    const [hover, setHover] = useState(false)
    const [hoverBackgroundStyle, setHoverBackgroundStyle] = useState({
        position:`absolute`,
        left: `0`,
        top:`0`,
        zIndex: `2`,
        width:`100%`,
        height: `100%`,
        background: `#094D92`,
        clipPath:`circle(0% at 0% 50%)`,
        transition:`clip-path .3s ease-in-out`
    })

    const [contactButtonStyle, setContactButtonStyle] = useState( {
        position: `relative`,
        zIndex: `5`,
        display: 'flex',
        alignItems:'center',
        width:'100%',
        height:`100%`,
        borderRadius:`2.5rem`,
        color: `#012242`,
        fontFamily: 'font:  "Roboto","HelveticaNeue-Light","Helvetica Neue Light","Helvetica Neue",Helvetica,Arial,"Lucida Grande",sans-serif',
        fontWeight: '400',
        lineHeight: '1',
        boxSizing: `border-box`,
        overflow: `hidden`,
        transition: `all .3s ease`,
    })

    const [contactButtonTextStyle, setContactButtonTextStyle] = useState({
        position:`relative`,
        zIndex: `40000`,
        display: `flex`,
        flexDirection: `row`,
        flexWrap: `wrap`,
        justifyContent: `flex-start`,
        alignItems: 'center',
        width: `100%`,
        height:`100%`,
        transition:`all .3s ease-in-out`
    })
    const [aHrefStyle, setAHrefStyle] = useState({
        textDecoration: 'none',
        position: 'relative',
        zIndex: '14',
        outline: 'none',
    })

    const [focusedContainerStyle, setFocusedContainerStyle] = useState({
        borderRadius: `2.5rem`,
        outline: `none`,
    })

    useEffect(()=>{
        setContactButtonTextStyle(prevState=>({
            ...prevState,
            flexWrap: bp768?`nowrap`:`wrap`,
        }))
    }, [bp576, bp768, bp1024]);


    useEffect(()=>{
        setHoverBackgroundStyle(prevState=>({
           ...prevState,
           clipPath: hover?`circle(150% at 75% 50%)`:`circle(0% at 0% 100%)`
        }))

        setContactButtonTextStyle(prevState=>({
           ...prevState,
           color:hover?`white`:`#094D92`,
        }))

        setFocusedContainerStyle(prevState=>({
            ...prevState,
            boxShadow: focused?`0 0 0 0.5rem #FFAE03`:`unset`,
            borderRadius: `2.5rem`
        }))
    }, [hover, focused])

    const over = (e) => {
        setHover(true)
    }

    const leave = (e) => {
        setHover(false)
    }

    const handleOnFocus = (e) => {
        setFocused(true)
    }

    const handleOnBlur = (e) => {
        setFocused(false)
    }

    return (
        <div className="focusedContainer" style={focusedContainerStyle}>
            <a role="button" aria-label={props.ariaLabel} href={props.href} style={aHrefStyle} onMouseEnter={over} onMouseLeave={leave} onFocus={handleOnFocus} onBlur={handleOnBlur}>
                <div style={contactButtonStyle} className={'btn'}>
                    <div style={contactButtonTextStyle}>
                        <div style={iconContainerStyle}>
                            <div style={iconNeuromorphicStyle} className={`neumorphic-shadow`}>
                                {props.icon}
                            </div>
                        </div>
                        <div style={{display:`flex`, flexdirection: 'row', justifyContent: 'flex-start'}} >
                            <div style={textContainerStyle}>
                                {props.children}
                            </div>
                        </div>
                    </div>
                    <div style={backgroundStyle} className={`whiteBack`}>
                        {/* background of the button */}
                    </div>

                    <div style={hoverBackgroundStyle} className={`whiteBack`}>
                        {/* background of the button when hover*/}
                    </div>
                </div>
            </a>
        </div>
    )
}

export default ContactButton