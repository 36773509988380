import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';

const Marker = props => {
    return <>
        <div className="pin"></div>
        <div className="pulse"></div>
    </>
}

class SimpleMap extends Component {
  static defaultProps = {
    center: {
      lat: 50.410617,
      lng: 3.844936
    },
    zoom: 20
  };

  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: '100%', width: '100%' }}>
        <GoogleMapReact
            bootstrapURLKeys={{ key: 'AIzaSyBgKkIZwrKhMG1A7hm9CbaL6STIjjLxhZo' }}
            defaultCenter={this.props.center}
            defaultZoom={this.props.zoom}
        >
            <Marker lat={ 50.410617} lng={ 3.844936}/>
        </GoogleMapReact>
      </div>
    );
  }
}

export default SimpleMap;
