import React from 'react';
import FancyBackground from '../components/FancyBackground';
import { StaticImage } from "gatsby-plugin-image";

const QuoteSection = (props) => {
    return (
        <>
            <div className={'min-width-50-pc'} style={{position:`relative`}}>
                <FancyBackground color="#ffd06d" size={1.0}>
                    <StaticImage src="../images/paul.png" alt="" height={1500}/>
                </FancyBackground>
            </div>
            <div className={'min-width-50-pc'}>
                <figure className="quote-container" style={{position:`relative`, display: `flex`, flexDirection:`column`}}>
                    <blockquote>
                        <q> Une idée, pour peu qu'on s'y accroche avec une conviction suffisante, qu'on la caresse et la berce avec soin, finira par produire sa propre réalité</q>
                    </blockquote>

                    <figcaption style={{alignSelf: `flex-end`, marginBlock: `1.5rem`}}>
                        <strong>Paul Watzlawick.</strong>
                    </figcaption>
                </figure>
            </div>
        </>
    )
}

export default QuoteSection