
import React from 'react';
import { StaticImage } from "gatsby-plugin-image";

const sectionStyle = {
    position: 'relative',
}

const divisionLineStyle = {
    position: 'absolute',
    zIndex: '10',
    bottom: '0',
    left: '0',
    transform: 'translateY(50%)',
    width: '100%',
}
class Section extends React.Component {

    render() {

        return (
            <section id={this.props.id} className={`w-100 root-section ${this.props.className?this.props.className:''}`} style={sectionStyle}>
                <div className="main-section mx-auto section-container">
                    <div className='resp-flex-container'>
                        {this.props.children}
                    </div>
                </div>
                <StaticImage  src="../images/barre_horizontale.png" alt="" style={divisionLineStyle}/>
            </section>
        )
    }
}

export default Section