import React, {useState, useEffect} from 'react';
import { FaHeart } from 'react-icons/fa';

import { useMediaQuery } from 'react-responsive';

const footerStyle = {
    background: '#012242',
    color: '#CCFFE2',
}


const Footer = (props) => {
    const bp1024 = useMediaQuery({ query: '(max-width: 1024px)' })
    const [containerStyle, setContainerStyle] = useState({
        maxWidth: '1260px',
        display:'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: 'auto 0',
        position : `relative`,
        left: `50%`,
        transform: `translateX(-50%)`,
    })

    const [footElementStyle, setFootElementStyle] = useState({
        margin: bp1024?`0rem 1rem`:'2rem',
        fontSize: '1.2rem',
        display: 'flex',
    })

    useEffect(() => {
        setContainerStyle(prevState=>({
            ...prevState,
            flexDirection: bp1024?`column`:`row`
        }))
        setFootElementStyle(prevState=>({
            ...prevState,
            margin: bp1024?`1rem`:`2rem`
        }))
    }, [bp1024])

    return (
        <footer id="footer" style={footerStyle}>
            <div className="container" style={containerStyle}>
                <div className="copyright" style={footElementStyle}>
                    © 2021 VALÉRIE VOLRAL
                </div>
                <div className="network">

                </div>
                <div className="madeby" style={footElementStyle}>
                    Made with <FaHeart style={{display:'inline-block', color: 'red', margin:'0 10px 0 10px'}}/> by François Volral
                </div>
            </div>
        </footer>
    )
}

export default Footer