import React, { useState, useEffect, useRef } from 'react';

const parentContainerStyle = {
    display: `flex`,
    flexDirection: `column`,
    position: `relative`
};

const responsiveCanvas = {
    width: `100%`,
    height: `100%`,
    position: `absolute`,
    left: `0`,
    top: `0`,
    zIndex: `0`
}

const getBorderPoint = (t, size) => ([
    .5 + Math.cos(t * Math.PI * 2 ) * .45 * size,
    .5 + Math.sin(t * Math.PI * 2 ) * .45 * size,
])

const getInitPoint = (W, H, size) => {
    var points = []
    var N = 8
    for (let i = 0; i < N; i++) {
        let t = i / N;

        const [x1, y1] = getBorderPoint(t, size)

        points.push({ x: x1 * W, y: y1 * H})
    }

    return points
}


const useResize = (parentContainer) => {
    const [size, setSize] = useState(getContainerSize(parentContainer));
    const [lastSize, setLastSize] = useState(0);

    useEffect(() => {
        const onResize = (event) => {
            setLastSize(size);
            let newSize = getContainerSize(parentContainer)

            if (lastSize !== newSize) {
                setSize(newSize);
            }
        }
        window.addEventListener("resize", onResize);
        return () => window.removeEventListener("resize", onResize);

    }, [size, lastSize, parentContainer]);

    return [size];
};



const getContainerSize = (parentContainer) => {
    if (!parentContainer || !parentContainer.current) {
        // Must wait DOM is loaded
        return {
            width: 800,
            height: 800,
        }
    }

    let size = 0;
    let element = parentContainer.current;
    size = element.clientWidth<800?element.clientWidth:800;

    return {
        width: size,
        height: size,
    }
}


export default function PricingCard(props) {
    const borderDeviation = useRef(Array.from({length:8}, () => {
        return {
            angle: Math.random() * Math.PI * 2,
        }
    }))

    const newBorderDeviation = useRef(Array.from({length:8}, () => {
        return {
            angle: Math.random() * Math.PI * 2,
            // timeToChange: Date.now() + 2000 + Math.random() * 2500
        }
    }))

    const W = useRef(0);
    const H = useRef(0);
    const lastTime = useRef(typeof window !== "undefined" ? window.performance.now():0);
    const newTime = useRef(typeof window !== "undefined" ? window.performance.now():0);
    const ctx = useRef(null);
    const parentContainer = useRef(null);


    const canvasEl = useRef(null);

    const [size] = useResize(parentContainer);

    useEffect(()=>{
        ctx.current = canvasEl.current.getContext("2d")
    }, []);

    useEffect(() => {
        W.current = size.width
        H.current = size.height
    }, [size.width, size.height]);

    useEffect(() => {
        let requestId;

        const animate = time => {
            // Change the state according to the animation
            if (!ctx || !ctx.current || !window) {
                return
            }

            lastTime.current = newTime.current
            newTime.current = window.performance.now()
            let deltatime = newTime.current - lastTime.current

            if (deltatime > 30) {
                deltatime = 30;
            }

            newBorderDeviation.current.forEach((value, idx) => {
                newBorderDeviation.current[idx].angle = newBorderDeviation.current[idx].angle + deltatime * 0.001
            });

            borderDeviation.current.forEach((value, idx) => {
                let distance = (newBorderDeviation.current[idx].angle - borderDeviation.current[idx].angle) * deltatime * 0.001
                borderDeviation.current[idx].angle += distance
            });

            ctx.current.clearRect(0, 0, W.current, H.current);

            let points = getInitPoint(W.current, H.current, props.size);

            ctx.current.lineWidth = 5;

            let newPoints = []
            points.forEach((point, idx) => {
                let pointX = point.x + Math.sin(borderDeviation.current[idx].angle) * 15;
                let pointY = point.y + Math.cos(borderDeviation.current[idx].angle) * 15;
                newPoints.push({x:pointX, y:pointY})
            })

            let currentIndex = newPoints[newPoints.length -1]
            let nextIndex = newPoints[0];
            let xc = currentIndex.x + (nextIndex.x - currentIndex.x) * 0.5;
            let yc = currentIndex.y + (nextIndex.y - currentIndex.y) * 0.5;


            ctx.current.beginPath()
            ctx.current.moveTo(xc, yc);

            for (var i = 0; i < newPoints.length; i++) {
                currentIndex = newPoints[i];
                nextIndex = i + 1 > newPoints.length - 1 ? newPoints[i - newPoints.length + 1] : newPoints[i + 1];
                xc = currentIndex.x + (nextIndex.x - currentIndex.x) * 0.5;
                yc = currentIndex.y + (nextIndex.y - currentIndex.y) * 0.5;

                ctx.current.quadraticCurveTo(currentIndex.x, currentIndex.y, xc, yc);
            }

            ctx.current.closePath()
            ctx.current.fillStyle = props.color;
            ctx.current.fill()
            requestId = requestAnimationFrame(animate);
        }
        animate();

        return () => {
            cancelAnimationFrame(requestId);
        };
    }, []);
    /**
     * Trig first `resize` in order to initialize the stage Size.
     */
    const [firstResize, setFirstResize] = useState(false);

    useEffect(()=>{
        if (!firstResize) {
            setFirstResize(true);
            window.dispatchEvent(new Event('resize'));
            W.current = size.width
            H.current = size.height
        }
    }, [firstResize])

    return(
        <div ref={parentContainer} style={parentContainerStyle}>
            <div style={{position:`relative`, zIndex: `2`}}>
                {props.children}
            </div>
            <canvas width={size.width} height={size.height} ref={canvasEl} style={responsiveCanvas}>

            </canvas>
        </div>
    );
}