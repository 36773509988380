import React, {useEffect, useState} from "react";

import { useMediaQuery } from 'react-responsive';
import { BsFillTelephoneInboundFill } from 'react-icons/bs';
import { FaRegEnvelope } from 'react-icons/fa';
import Hamburger from 'hamburger-react'

import ContactSmallButton from '../components/ContactSmallButton';


const navBarContainerStyle = {
    position: `fixed`,
    display:`flex`,
    width:`100%`,
    height:`100vh`,
    alignItems: `center`,
    justifyContent:`space-evenly`,
    flexDirection: `column`,
    fontSize:`3rem`,
    textDecoration: 'none',
    color: '#012242',
    listStyleType: `none`,
}


const NavBar = props => {
    const [open, setOpen] = useState(false)
    const [skipToContentButtonFocused, setSkipToContentButtonFocused] = useState(false)

    const bp1024 = useMediaQuery({ query: '(min-width: 769px) and (max-width: 1024px)' })
    const bp768 = useMediaQuery({ query: '(min-width: 577px)  and (max-width: 768px)' })
    const bp576 = useMediaQuery({ query: '(max-width: 576px)' })

    const [hamburgerContainerStyle, setHamburgerContainerStyle] = useState({
        position: 'relative',
        zIndex: `25`,
        background: `#ccffe2`,
        borderRadius: `100%`,
        pointerEvents: `all`,
    });

    const [navBarStyle, setNavBarStyle] = useState({
        position:`absolute`,
        zIndex: `20`,
        left:`0`,
        top:`0`,
        width:`100%`,
        height:`100%`,
        transition: `all .5s`,
    })

    const [skipToContentButtonStyle, setSkipToContentButtonStyle]= useState({
        overflow: 'hidden',
        opacity: 0,
        height: 0,
        width: 0,
        background: `#094D92`,
        color: `#CCFFE2`,
        pointerEvents: `all`,
    })

    const [headerContainerMenuStyle, setHeaderContainerMenuStyle] = useState({
        width: '100%',
        zIndex: `15`,
        position: `fixed`,
        height: `100%`,
        transition: `all .6s ease-in-out`,
        pointerEvents: `none`,
    })

    const topContactBtnSuperContainerStyle = {
        maxWidth: '1260px',
        margin:`auto`,
        zIndex: `15`,
        display:`flex`,
        flexDirection: 'row',
        justifyContent: 'flex-end',
    }

    const hamburgerMenuSuperContainerStyle = {
        maxWidth: '1260px', margin:`auto`, height:`100%`,
    }

    const [topContactBtnContainerStyle, setTopContactBtnContainerStyle] = useState({
        display: `flex`,
    })

    const onClick = (e) => {
        setOpen(!open)
    }

    useEffect(()=>{
        setHamburgerContainerStyle((prevState)=> ({
            ...prevState,
            margin: bp576?`.25rem`:`.5rem`,
            padding: bp576?`.5rem`:`1.5rem`,
        }))

        setTopContactBtnContainerStyle(prevState=>({
            ...prevState,
            flexDirection: bp576?`column`:`row`,
            // width:bp576?`48px`:``,
        }))
    }, [bp576, bp768, bp1024])

    useEffect(()=>{
        setHeaderContainerMenuStyle(prevState=>({
            ...prevState,
            background: open?`#ccffe2`:`unset`,
        }))

        setNavBarStyle(prevState=>({
            ...prevState,
            clipPath: open?`circle(200% at 0 0)`: `circle(0px at 0 0)`,
            pointerEvents: open?`all`:`none`,
            background: `#CCFFE2`,
        }))

        setSkipToContentButtonStyle(prevState=>({
            ...prevState,
            width: skipToContentButtonFocused?`unset`:`1px`,
            height: skipToContentButtonFocused?`unset`:`1px`,
            opacity: skipToContentButtonFocused?`unset`:`0`,
            padding: skipToContentButtonFocused?`1rem`:'0',
        }))
    }, [open, skipToContentButtonFocused])

    const handleSkipButtonOnFocus = (e) => {
        setSkipToContentButtonFocused(true)
    }

    const handleSkipButtonOnBlur = (e) => {
        setSkipToContentButtonFocused(false)
    }

    const onKeyPress = (e) => {
        debugger;
        switch (e.keyCode) {
            case "Enter":
                setOpen(!open)
                break
            case "Esc":
                setOpen(false)
                break
            default:
                break;
        }
    }
    return (
        <>
            <div className={`hamburger-react-container`} style={headerContainerMenuStyle}>
                <div style={hamburgerMenuSuperContainerStyle}>

                    <div style={{display:`flex`, flexDirection: 'row', alignItems: 'center', width: '100%'}}>
                        <a
                            tabindex="0"
                            class="skip-main"
                            href="#who-section"
                            onFocus={handleSkipButtonOnFocus}
                            onBlur={handleSkipButtonOnBlur}
                            style={skipToContentButtonStyle}
                        >
                            Aller au contenu
                        </a>

                        <div className={`neumorphic-shadow-2`} style={hamburgerContainerStyle}>
                            <Hamburger onKeyPress={onKeyPress} size={bp576?32:48} label="Bouton afficher navigation" hideOutline={false} rounded toggled={open} toggle={setOpen} style={{zIndex: `30`}}/>
                        </div>

                    </div>


                    <nav className={`navbar-main ${open?`display-navbar`:``}`} style={navBarStyle}>
                        <ul role="menubar" className="navbar-container" style={navBarContainerStyle}>
                            <li role="none">
                                <a
                                    role="menuitem"
                                    tabIndex={open?'0':'-1'}
                                    className="navbar-link"
                                    style={{textDecoration: 'none'}}
                                    href="#who-section"
                                    onClick={onClick}
                                    onKeyPress={onKeyPress}
                                >
                                    Qui Suis-je ?
                                </a>
                            </li>
                            <li role="none">
                                <a
                                    role="menuitem"
                                    tabIndex={open?'0':'-1'}
                                    className="navbar-link"
                                    style={{textDecoration: 'none'}}
                                    href="#when-section"
                                    onClick={onClick}
                                    onKeyPress={onKeyPress}
                                >
                                    Quand Consulter ?
                                </a>
                            </li>
                            <li role="none">
                                <a
                                    role="menuitem"
                                    tabIndex={open?'0':'-1'}
                                    className="navbar-link"
                                    style={{textDecoration: 'none'}}
                                    href="#pricing-section"
                                    onClick={onClick}
                                    onKeyPress={onKeyPress}
                                >
                                    Tarifs et remboursements
                                </a>
                            </li>
                            <li role="none">
                                <a
                                    role="menuitem"
                                    tabIndex={open?'0':'-1'}
                                    className="navbar-link"
                                    style={{textDecoration: 'none'}}
                                    href="#contact-section"
                                    onClick={onClick}
                                    onKeyPress={onKeyPress}
                                >
                                    Contactez-moi
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
            <div className={`top-btn-contact-container`} style={topContactBtnSuperContainerStyle}>
                    <div className="top-contact-button" style={topContactBtnContainerStyle}>
                        <ContactSmallButton
                            ariaLabel={`Bouton pour envoyer un mail à l'adresse valerie.volral@gmail.com`}
                            href={`mailto:valerie.volral@gmail.com`}
                            icon={<FaRegEnvelope/>}
                        />

                        <ContactSmallButton
                            ariaLabel={`Bouton pour me contacter au numéro 065 67 43 14.`}
                            href={`tel:065674314`}
                            icon={<BsFillTelephoneInboundFill/>}
                        />
                    </div>
            </div>
        </>
    )
}

export default NavBar;