import React, {useCallback, useState, useRef} from 'react';
import PricingCard from '../components/PricingCard';

const pricingGrid = {
    display: `flex`,
    flexDirection: 'column',
}


const PricingCardGrid = () => {
    const focusIdx = useRef(0);
    const [opened, setOpened] = useState([
        false,
        false,
        false,
        false,
    ])

    const memoizedHandleKeyDown = useCallback((e) => {
        let elem;

        switch(e.key) {
            case "Home":
                focusIdx.current = 0;
                elem = document.querySelector(`#pricing-card-${focusIdx.current}-label`);
                elem.focus()
                e.preventDefault();
                break;
            case "End":
                e.preventDefault();
                focusIdx.current = 3;
                elem = document.querySelector(`#pricing-card-${focusIdx.current}-label`);
                elem.focus()
                break
            case "ArrowDown":
                focusIdx.current = (focusIdx.current + 1) % 4;
                elem = document.querySelector(`#pricing-card-${focusIdx.current}-label`);
                elem.focus()

                e.preventDefault();
                break
            case "ArrowUp":
                focusIdx.current = focusIdx.current - 1;
                if (focusIdx.current < 0) {
                    focusIdx.current = focusIdx.current + 4
                }

                elem = document.querySelector(`#pricing-card-${focusIdx.current}-label`);
                elem.focus()
                e.preventDefault();
                break
            default:
                return;
        }
     }, []);

    const handleOnFocus = (e) => {
        focusIdx.current = Number.parseInt(e.currentTarget.dataset.focusOrder)
    }

    const closeOthers = (openedIdx) => {
        let lastState = opened[openedIdx];
        const openedState = [false, false, false, false]
        openedState[openedIdx] = !lastState;
        setOpened(openedState)
    }

    return (
        <div style={pricingGrid} className={'pricing-grid'}>
            <PricingCard
                title="Solidaris"
                ariaName="pricing-card-0"
                handleKeyDown={memoizedHandleKeyDown}
                handleOnFocus={handleOnFocus}
                focusOrder={0}
                closeOthers={closeOthers}
                opened={opened[0]}
            >
                <p>
                    La mutuelle prend à sa charge 20€ par séance pour un maximum de 240€ par an.<br/>
                    Je fournis l'attestation (pas de formulaire à imprimer).
                </p>
                <br/>

                <h5>Conditions :</h5>

                <ul>
                    <li>Avoir un DMG. (Dossier Médical Global)</li>
                    <li>Etre en ordre de cotisations à l'Assurance Complémentaire</li>
                </ul>
            </PricingCard>
            <PricingCard
                title="Mutualité Chrétienne"
                ariaName="pricing-card-1"
                handleKeyDown={memoizedHandleKeyDown}
                handleOnFocus={handleOnFocus}
                focusOrder={1}
                closeOthers={closeOthers}
                opened={opened[1]}
            >
                <div className={'content'}>
                    <p>
                        La mutuelle prend à sa charge 15€ par séance pour un plafond maximum de 240€.<br/><br/>
                        Formulaire à télécharger et imprimer.
                    </p>
                </div>
            </PricingCard>
            <PricingCard
                title="Partenamut (fusionné avec Omnimut)"
                ariaName="pricing-card-2"
                handleKeyDown={memoizedHandleKeyDown}
                handleOnFocus={handleOnFocus}
                focusOrder={2}
                closeOthers={closeOthers}
                opened={opened[2]}
            >
                <div className={'content'}>
                    <p>
                        20€ par séance, 12 séances par an et sous certaines conditions 16 séances.<br/><br/>
                        Formulaire à télécharger et imprimer.
                    </p>
                </div>
            </PricingCard>
            <PricingCard
                title="La Mutualité Neutre"
                ariaName="pricing-card-3"
                handleKeyDown={memoizedHandleKeyDown}
                handleOnFocus={handleOnFocus}
                focusOrder={3}
                closeOthers={closeOthers}
                opened={opened[3]}
            >
                <div className={'content'}>
                    <p>
                        Via l'assurance complémentaire, vous bénéficiez d'un remboursement jusqu'à 180€ pour 12 séances maximum par an.
                    </p>
                </div>
            </PricingCard>
        </div>
    )
}

export default PricingCardGrid