import React, {useEffect, useState} from "react";
import { useMediaQuery } from 'react-responsive';

const iconContainerStyle = {
    display: `flex`, 
    flexDirection:`column`,
    justifyContent: 'center',
    alignItems: 'center',
    color:`#094D92`,
    background: `#FFFFFF`,
}

const ContactSmallButton = (props) => {
    const bp1024 = useMediaQuery({ query: '(min-width: 769px) and (max-width: 1024px)' })
    const bp768 = useMediaQuery({ query: '(min-width: 577px)  and (max-width: 768px)' })
    const bp576 = useMediaQuery({ query: '(max-width: 576px)' })

    const [focused, setFocused] = useState(false)

    const [hover, setHover] = useState(false)

    const aHrefStyle = {
        textDecoration: 'none',
        position: 'relative',
        zIndex: '14',
        outline: 'none',
    }

    const [focusedContainerStyle, setFocusedContainerStyle] = useState({
        borderRadius: `100%`,
        outline: `none`,
        margin: `.5rem`,
    })

    const iconNeuromorphicStyle = {
        borderRadius:`100%`,
        padding:`1.5rem`,
        background: `#FFF`,
        display: `flex`,
        justifyContent: 'center',
        alignItems: 'center',
    }

    useEffect(()=>{
        setFocusedContainerStyle(prevState=>({
            ...prevState,
            boxShadow: focused?`0 0 0 0.5rem #FFAE03`:`unset`,
        }))
    }, [hover, focused])

    useEffect((prevState)=>({
        ...prevState,
        margin: bp576?`.5rem`:`.5rem`,
        padding: bp576?`.5rem`:`1.5rem`,
    }), [bp576, bp768, bp1024]);

    const over = (e) => {
        setHover(true)
    }

    const leave = (e) => {
        setHover(false)
    }

    const handleOnFocus = (e) => {
        setFocused(true)
    }

    const handleOnBlur = (e) => {
        setFocused(false)
    }

    return (
        <div className="focusedContainer" style={focusedContainerStyle}>
            <a className={`top-btn`} role="button" aria-label={props.ariaLabel} href={props.href} style={aHrefStyle} onMouseEnter={over} onMouseLeave={leave} onFocus={handleOnFocus} onBlur={handleOnBlur}>
                <div className={`neumorphic-shadow-2`} style={iconNeuromorphicStyle}>
                    <div style={iconContainerStyle}>
                        {props.icon}
                    </div>
                </div>
            </a>
        </div>
    )
}

export default ContactSmallButton