import React from 'react';

import SubSection from '../components/SubSection';
import PricingCardGrid from '../components/PricingCardGrid';
import FancyBackground from '../components/FancyBackground';

const IrisAnimationClientSideOnlyLazy = React.lazy(() =>
    import("../components/IrisAnimation")
)

const PricingSection = (props) => {

    const isSSR = typeof window === "undefined"
    return (
        <div style={{margin:'1rem'}}>
            <div className="pricing-first-section" style={{width:`100%`, marginTop:`2.5rem`}}>
                <div style={{padding:`1rem`}}>
                    <div style={{display: `flex`, flexDirection: `row`, width: `100%`}}>
                        <h2>Tarifs et remboursements</h2>
                    </div>
                    <p>
                        50€ par séance. Je dispose de l'agrément délivré par la <a className="outside-link" href="https://www.compsy.be/fr/" target="_blank" rel="noopener noreferrer">Commission des Psychologues</a>. Mes séances sont remboursées par les mutuelles (<a className="outside-link" href="https://www.compsy.be/fr/remboursement-psychologie-clinique" aria-label="Visitez ce lien pour plus d'informations à propos du remboursement des mutuelles" target="_blank" rel="noopener noreferrer">Plus d'informations</a>).
                    </p>
                    <SubSection title="Je suis psychologue de première ligne">
                        <div className="min-width-50-pc">
                            <p >
                                Si vous passez par votre médecin traitant pour vous ou votre enfant avant de me consulter,
                                vous bénéficiez d'une série de quatres séances prises en charge par l'<span className={`no-hyphenate`}>INAMI</span>. Ces séances ne vous coûtent que 11€ (4€ pour les patients bénéficiant d'un régime préférentiel). Ces séances sont renouvelables une fois en cas de nécessité et en accord avec votre médecin
                                traitant.
                            </p>
                        </div>
                    </SubSection>
                </div>

                <FancyBackground color="#CCFFE2" size={0.65}>
                    {!isSSR && (
                        <React.Suspense fallback={<div/>}>
                            <IrisAnimationClientSideOnlyLazy/>
                        </React.Suspense>
                    )}
                </FancyBackground>
            </div>
            <div style={{padding:`1rem`}}>
                <SubSection title="Quelques informations à propos des remboursements">
                    <i>(Dernière mise à jour Octobre 2021)</i><br/>
                    <PricingCardGrid/>
                </SubSection>
            </div>
            <div style= {{height: `2.5rem`}}>

            </div>
        </div>
    )
}

export default PricingSection