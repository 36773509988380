import React, {useState, useEffect} from "react";
import { IoIosArrowForward } from 'react-icons/io';

const parentContainerStyle = {
    padding: `.5rem`,
    display: `flex`,
    flexDirection: `column`,
    position: `relative`,
};

const iconContainerStyle = {
    display: `flex`,
    flexDirection:`column`,
    justifyContent: 'center',
    position: `relative`,
    color:`#094D92`,
    height:`100%`,
}

const iconNeuromorphicStyle = {
    position:`relative`,
    borderRadius:`100%`,
    padding:`1rem`,
    margin: `2rem 1rem`,
    height: `4rem`,
    width: `4rem`,
}

const PricingCard = (props) => {
    const [labelIsFocused, setLabelIsFocused] = useState(false);

    const [cardContentStyle, setCardContentStyle] = useState({
        position:`relative`,
        zIndex: `2`,
        transition: `max-height .3s ease, padding .0s .3s `,
        overflow: `hidden`,
        maxHeight: `0px`,
        background: `#FFFFFF`,
        borderRadius: `0rem 0rem 1rem 1rem`,
    });

    const [iconStyle, setIconStyle] = useState({
        position: `absolute`,
        left:`50%`,
        top:`50%`,
        display: `flex`,
        justifyContent: 'center',
        alignItems:`center`,
        transform:`translate(-50%, -50%) rotate(90deg)`,
        rotate: `42deg`,
        height: `3rem`,
        width: `3rem`,
        transition: `transform 0.3s ease`
    })

    const [cardHeaderStyle, setCardHeaderStyle] = useState({
        display: `flex`,
        flexDirection: `row`,
        alignItems: 'center',
        justifyContent: 'space-between',
        background: `#FFFFFF`,
        padding:`.5rem`,
        overflow: `hidden`,
        borderRadius: `1rem 1rem 1rem 1rem`,
        marginBottom: `-1px`,
        width: `100%`,
    })

    const [cardTitleTextStyle, setCardTitleTextStyle] = useState({
        marginBottom: `0px`
    })

    useEffect(()=> {
        setCardContentStyle(prevState=>({
            ...prevState,
            maxHeight: props.opened?`1000px`:`0px`,
            padding: props.opened?`1rem`:`0px`,
            transition: props.opened?`max-height .3s ease, padding .0s .0s `:`max-height .3s ease, padding .0s .3s `,
        }))

        setIconStyle(prevState=>({
            ...prevState,
            transform: props.opened?`translate(-50%, -50%) rotate(90deg)`:`translate(-50%, -50%) rotate(0deg)`,
        }))

        setCardHeaderStyle(prevState=>({
            ...prevState,
            borderRadius: props.opened?`1rem 1rem 0rem 0rem`:`1rem 1rem 1rem 1rem`,
            transitionDelay: props.opened?`none`:`.3s`,
            transitionProperty: props.opened?`none`:`border-radius`,
        }))
    }, [props.opened])

    useEffect(()=>{
        setCardHeaderStyle(prevState=>({
            ...prevState,
            background:labelIsFocused?`#ABACF2`: `#FFFFFF`,
            boxShadow:labelIsFocused?`inset 0px 0px 0px .25rem #CCFFE2`: `none`,
        }))

        setCardTitleTextStyle(prevState=>({
            ...prevState,
        }))
    }, [labelIsFocused])

    const clickToOpen = (e)=>{
        props.closeOthers(props.focusOrder);
    }

    const handleLabelFocus = (e) => {
        setLabelIsFocused(true);
        props.handleOnFocus(e);
    }

    const handleLabelOnBlur = (e) => {
        setLabelIsFocused(false);
    }


    return (
        <div style={parentContainerStyle} className={`accordion`}>
            <h4 className={`accordion-trigger`} style={{marginBottom: `0`}}>
                <button
                    aria-expanded={props.opened?`true`:`false`}
                    aria-controls={`${props.ariaName}-region`}
                    id={`${props.ariaName}-label`}
                    className={`card-header`}
                    style={cardHeaderStyle}
                    onClick={clickToOpen}
                    onKeyDown={props.handleKeyDown}
                    onFocus={handleLabelFocus}
                    onBlur={handleLabelOnBlur}
                    data-focus-order={props.focusOrder}
                >
                    <div className={`text`} style={cardTitleTextStyle}>{props.title}</div>
                    <div className={`icon-container`} style={iconContainerStyle}>
                        <div style={iconNeuromorphicStyle} className={`neumorphic-shadow`}>
                            <IoIosArrowForward style={iconStyle}/>
                        </div>
                    </div>
                </button>
            </h4>

            <div
                id={`${props.ariaName}-region`}
                role="region" aria-labelledby={`${props.ariaName}-label`}
                className={`card-content`}
                style={cardContentStyle}
            >
                {props.children}
            </div>
        </div>
    )
}

export default PricingCard