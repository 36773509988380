import React, {useEffect, useState} from 'react';

import { useMediaQuery } from 'react-responsive';

const containerStyleDefault = {
    display: `flex`,
    width: `100%`,
    flexWrap: `wrap`,
    justifyContent: `space-between`,
}

const dataStyle = {
    fontWeight:`700`,
}

const OtherInformationsSection = (props) => {
    const bp1024 = useMediaQuery({ query: '(min-width: 769px) and (max-width: 1024px)' })
    const bp768 = useMediaQuery({ query: '(min-width: 577px)  and (max-width: 768px)' })
    const bp576 = useMediaQuery({ query: '(max-width: 576px)' })
    const [otherDataStyle, setOtherDataStyle] = useState(
        {margin :`0 2rem`}
    )

    useEffect(()=> {
        setOtherDataStyle(prevState=>({
            ...prevState,
            margin: bp576?`0.45rem`:`0.5rem`,
        }))

    }, [bp1024, bp768, bp576])
    let containerStyle = containerStyleDefault

    return (
        <div style={containerStyle}>
            <div className={`normal-text`} style={otherDataStyle}>
                <div style={dataStyle}>N° Inscription ComPsy</div>
                <div>822226319</div>
            </div>
            <div className={`normal-text`} style={otherDataStyle}>
                <div style={dataStyle}>VISA Psychologue</div>
                <div>311299</div>
            </div>
            <div className={`normal-text`} style={otherDataStyle}>
                <div style={dataStyle}>Numéro d'entreprise</div>
                <div>BE 0752.922.809</div>
            </div>
        </div>
    )
}

export default OtherInformationsSection;