import React, {useEffect, useState} from "react";

import { useMediaQuery } from 'react-responsive';
import { StaticImage } from "gatsby-plugin-image";
import { FaRegEnvelope } from 'react-icons/fa';
import { BsFillTelephoneInboundFill } from 'react-icons/bs';

import SimpleMap from './GoogleMap';
import FancyBackground from '../components/FancyBackground';
import ContactButton from '../components/ContactButton';

const contactResponsiveGridStyleDefault = {
    display: `grid`,
    gridTemplateColumns: `1fr 1fr`,
    // gridTemplateRows: `12.50em 1fr 1fr 1fr 1fr 8.25em`,
    gridTemplateRows: `auto auto auto 500px auto auto`,
    gridTemplateAreas: `
        "title   mail-image"
        "title   mail-image"
        "contact map"
        "contact map"
        "mail    phone"
        "place   place"
        "place   place"
    `,
    gridGap: `1rem`,
    marginBottom: `3rem`,
}

const contactResponsiveGridStyleBp1024 = {
    display: `grid`,
    gridTemplateColumns: `1fr 1fr`,
    gridTemplateRows: `auto auto auto 500px auto`,
    gridTemplateAreas: `
        "title   mail-image"
        "contact contact"
        "mail    phone"
        "map     map"
        "place   place"
    `,
    gridGap: `1rem`,
    marginBottom: `3rem`,
}

const contactResponsiveGridStyleBp768 = {
    display: `grid`,
    gridTemplateColumns: `1fr 1fr`,
    gridTemplateRows: `auto auto auto 500px auto`,
    gridTemplateAreas: `
        "title   mail-image"
        "contact contact"
        "mail    phone"
        "map     map"
        "place   place"
    `,
    gridGap: `1rem`,
    marginBottom: `3rem`,
}

const contactResponsiveGridStyleBp576 = {
    display: `grid`,
    gridTemplateColumns: `1fr`,
    gridTemplateRows: `auto auto auto auto auto 500px auto`,
    gridTemplateAreas: `
        "title"
        "mail-image"
        "contact"
        "mail"
        "phone"
        "map"
        "place"
    `,
    gridGap: `1rem`,
    marginBottom: `3rem`,
}


const PanelCardStyle = {
    display:`flex`,
    flexDirection:`column`,
    height: `100%`,
}

const PanelCard = (props) => {
    return (
        <div style={PanelCardStyle}>
            <h2>{props.title}</h2>
            {props.children}
        </div>
    )
}


const Map = (props) => {
    return (
        <SimpleMap/>
    )
}

const ContactSection = (props) => {

    const bp1024 = useMediaQuery({ query: '(min-width: 769px) and (max-width: 1024px)' })
    const bp768 = useMediaQuery({ query: '(min-width: 577px)  and (max-width: 768px)' })
    const bp576 = useMediaQuery({ query: '(max-width: 576px)' })

    const [contactResponsiveGridStyle, setContactResponsiveGridStyle] = useState({})

    useEffect(()=>{
        if (bp576 || bp768 || bp1024) {
            if (bp576) {
                setContactResponsiveGridStyle(contactResponsiveGridStyleBp576);
            }
            if (bp768) {
                setContactResponsiveGridStyle(contactResponsiveGridStyleBp768);
            }
            if (bp1024) {
                setContactResponsiveGridStyle(contactResponsiveGridStyleBp1024);
            }
        } else {
            setContactResponsiveGridStyle(contactResponsiveGridStyleDefault);
        }

    }, [bp576, bp768, bp1024]);

    return (
        <div style={contactResponsiveGridStyle}>
            <div style={{gridArea: `title`, alignSelf:`center`, justifySelf: `left`}}>
                <h1>Contactez-Moi</h1>
            </div>
            <div style={{gridArea: `mail-image`, alignSelf:`center`, justifySelf: `center`, position: `relative`, zIndex: `10`}}>
                <FancyBackground color="#ABACF2" size={1.0}>
                    <StaticImage src="../images/mail_contact.png" alt="" height={300}/>
                </FancyBackground>
            </div>
            <div style={{gridArea: `contact`}}>
                <PanelCard title={`Consultations`}>
                    <p>Je vous accueille au sein de mon cabinet au <span style={{fontStyle:`italic`, fontWeight: 'bold'}}>Centre Okineo 117, Rue Saint-Pierre à Wasmes.</span>&nbsp;
                    Je réalise également des rendez-vous à domicile. Une téléconsultation est aussi possible via le logiciel Teams.</p>
                    <p>Pour tout renseignement concernant les modalités de ces deux types de services, merci de me contacter par téléphone ou par mail.</p>
                </PanelCard>
            </div>
            <div style={{gridArea:`place`}}>
                <StaticImage src="../images/cabinet_consult.jpg" alt="" />
            </div>
            <div style={{gridArea: `mail`, alignSelf:`center`, height:`100%`}}>
                <ContactButton ariaLabel={`Bouton pour envoyer un mail à l'adresse valerie.volral@gmail.com`} href={`mailto:valerie.volral@gmail.com`} icon={<FaRegEnvelope/>}>
                    <div>Envoyer un email</div>
                </ContactButton>
            </div>

            <div style={{gridArea: `phone`, alignSelf:`center`, height:`100%`}}>
                <ContactButton ariaLabel={`Bouton pour me contacter au numéro 065 67 43 14`} href={`tel:065674314`} icon={<BsFillTelephoneInboundFill/>}>
                    <div>Appelez moi</div>
                </ContactButton>
            </div>

            <div style={{gridArea: `map`}}>
                <Map/>
            </div>
        </div>
    )
}

export default ContactSection