import React, {useEffect, useState} from "react";

import { StaticImage } from "gatsby-plugin-image";

import { useMediaQuery } from 'react-responsive';
import { GoMortarBoard } from 'react-icons/go';
import { TiMortarBoard } from 'react-icons/ti';

import SubSection from '../components/SubSection';

const gridContainerStyle = {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridTemplateRows: '120px auto auto auto auto auto ',
    gridAutoRows: 'auto',
    gridTemplateAreas: `
        '.             title        title'
        'illustration welcome  welcome'
        'illustration parcours     .'
        'illustration parcours_p1  parcours_p1'
        'illustration parcours_p2  parcours_p2'
        'formations   formations   formations'
    `,
    width: '100%',
}

const gridContainerStyleBP1024 = {
    display: 'grid',
    gridTemplateColumns: '50% 50%',
    gridTemplateRows: '120px auto',
    gridAutoRows: 'auto',
    gridTemplateAreas: `
        'title        title'
        'welcome      welcome'
        'illustration parcours'
        'illustration parcours_p1'
        'illustration parcours_p2'
        'formations   formations'
    `,
    width: '100%',
}

const gridContainerStyleBP768 = {
    display: 'grid',
    gridTemplateColumns: '50% 50%',
    gridTemplateRows: '120px auto',
    gridAutoRows: 'auto',
    gridTemplateAreas: `
        'title        title'
        'welcome      welcome'
        'illustration parcours'
        'illustration parcours_p1'
        'parcours_p2 parcours_p2'
        'formations formations'
    `,
    width: '100%',
}


const gridContainerStyleBP576 = {
    display: 'grid',
    gridTemplateColumns: '50% 50%',
    gridTemplateRows: '3.75rem auto',
    gridAutoRows: 'auto',
    gridTemplateAreas: `
        'title        title'
        'welcome      welcome'
        'illustration illustration'
        'parcours     parcours'
        'parcours_p1  parcours_p1'
        'parcours_p2 parcours_p2'
        'formations formations'
    `,
    width: '100%',
}

const WhoSection = (props) => {
    const bp1024 = useMediaQuery({ query: '(min-width: 769px) and (max-width: 1024px)' })
    const bp768 = useMediaQuery({ query: '(min-width: 577px)  and (max-width: 768px)' })
    const bp576 = useMediaQuery({ query: '(max-width: 576px)' })
    const [containerStyle, setContainerStyle] = useState({})

    useEffect(()=>{
        if (bp1024 || bp768 || bp576) {
            if (bp1024) {
                setContainerStyle(gridContainerStyleBP1024);
            }
            if (bp768) {
                setContainerStyle(gridContainerStyleBP768);
            }
            if (bp576) {
                setContainerStyle(gridContainerStyleBP576);
            }
        } else {
            setContainerStyle(gridContainerStyle);
        }
    }, [bp576, bp768, bp1024])

    return (
        <div className="first-section-grid-container" style={containerStyle}>
            <div style={{gridArea: 'title', alignSelf:'baseline'}}>
                <h2>{bp576?`Bienvenue,`:`Bonjour et bienvenue,`}</h2>
            </div>
            <div style={{gridArea: 'illustration', alignSelf:'center'}}>
                <StaticImage src="../images/pierres.png" alt="" height={800} />
            </div>

            <div style={{gridArea: 'welcome', alignSelf:'center'}}>
                <p>
                Je suis <strong style={{fontWeight:`normal`}}>Valérie Volral</strong>, psychologue clinicienne de formation,
                spécialisée pour les enfants, adolescents et familles.
                </p>
            </div>

            <h3 style={{gridArea: 'parcours'}}>Parcours</h3>

            <p style={{gridArea: 'parcours_p1'}}>
                Diplômée de l'UMONS, licenciée en psychologie clinique, je pratique depuis 15 ans.
                Je suis spécialisée dans la prise en charge de la gestion du stress, des peurs
                difficilement contrôlables (difficultés à affronter le regard des autres, peur de
                la maladie) et du mal-être (dépression, deuil, burn-out). J'aide également les
                personnes qui manquent de confiance en elles (estime, confiance et affirmation
                de soi).
            </p>
            <p style={{gridArea: 'parcours_p2'}}>
                Je vous offre un espace de parole ainsi qu'une expertise clinique, quelle que soit
                la situation de crise ou de questionnement dans laquelle vous êtes (dépression, anxiété,
                questionnement par rapport à l'éducation de vos enfants, orientation professionnelle
                ou vie amoureuse/affective, etc.).
            </p>

            <SubSection title="Formations" style={{gridArea: 'formations'}}>
                <div>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'baseline', marginBottom:`1rem`}}>
                        <GoMortarBoard style={{marginRight: '10px',  minWidth: '20px'}}/> <div>Psychologue clinicienne diplômée de<span style={{fontStyle:`italic`}}>l'UMONS</span>.</div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'baseline', marginBottom:`1rem`}}>
                        <GoMortarBoard style={{marginRight: '10px', minWidth: '20px'}}/><div>Formation en thérapie brève chez&nbsp;<span style={{fontStyle:`italic`}}>Primes ASBL</span>.</div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'baseline', marginBottom:`1rem`}}>
                        <TiMortarBoard style={{marginRight: '10px', minWidth: '20px'}}/> <div>Thérapie cognitive comportementale et émotionnelle à&nbsp;<span style={{fontStyle:`italic`}}>l'UMONS</span> (en cours de supervision).</div>
                    </div>
                </div>
            </SubSection>
        </div>
    )
}

export default WhoSection;