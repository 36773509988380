import React from 'react';

class SubSection extends React.Component {
    render() {
        return (
            <section className={'normal-text'} style={this.props.style}>
                <h3>{this.props.title}</h3>
                {this.props.children}
            </section>
        )
    }
}

export default SubSection