import React, {useEffect, useState, useRef} from "react";

import { Helmet } from 'react-helmet';

import { useMediaQuery } from 'react-responsive';

import ContactSection from '../components/ContactSection';
import Footer from '../components/Footer';
import NavBar from '../components/NavBar';
import QuoteSection from '../components/QuoteSection';
import PricingSection from '../components/PricingSection';
import Section from '../components/Section';
import OtherInformationsSection from '../components/OtherInformationsSection';
import WhoSection from '../components/WhoSection';
import WhenSection from '../components/WhenSection';

const pageStyles = {
    position: 'relative',
    background: '#CCFFE2',
    font: '14px/21px "Roboto","HelveticaNeue-Light","Helvetica Neue Light","Helvetica Neue",Helvetica,Arial,"Lucida Grande",sans-serif',
    fontWeight: '300',
    verticalAlign: 'baseline',
    width: '100%',
    height:'100%',
}


const IndexPage = () => {
    const bp1024 = useMediaQuery({ query: '(min-width: 769px) and (max-width: 1024px)' })
    const bp768 = useMediaQuery({ query: '(min-width: 577px)  and (max-width: 768px)' })
    const bp576 = useMediaQuery({ query: '(max-width: 576px)' })

    const [phantomDivStyle, setPhantomDivStyle] = useState({
        height: `100px`
    })

    const scrollPosition = useRef(0);

    const handleScroll = () => {

        if (typeof window !== "object") {
            debugger
            return
        }

        const position = window.pageYOffset;
        scrollPosition.current = position;

    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        let phantomHeight = bp576?`3.0rem`:bp768?`4rem`:bp1024?`5rem`:`6.25rem`;
        setPhantomDivStyle(prevState=>({
            height: phantomHeight,
        }))
    }, [bp576, bp768, bp1024]);

    return (
        <main style={pageStyles} role="main">
            <Helmet   htmlAttributes={{
                lang: 'fr',
            }}>
                <title>Valérie Volral - Psychologue Clinicienne à Colfontaine</title>
                <meta charset="utf-8"/>
                <meta name="theme-color" content="#CCFFE2"/>
                <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
                <meta name="description" content="Psychologue Clinitienne à Colfontaine - 065/67 43 14 - Enfants, Ados, Adultes et Famille - Déprime, Trouble Anxieux, Éducation des enfants"/>
                <meta name="keyword" content="VALERIE VOLRAL, Clinicien, Enfant, Adulte, Thérapie, Psy, Colfontaine, Wasmes, Paturage, Borinage, Psychologie"/>
                <meta name="geography" content="Colfontaine, Belgique, 7340"/>
                <meta name="revisit-after" content="15 days"/>
                <meta name="author" content="françois volral"/>
                <meta name="robots" content="index,follow,all" />

                <meta property="og:url" content="https://valerievolralpsy.be"/>
                <meta property="og:type" content="website"/>
                <meta property="og:locale" content="fr_BE"/>
                <meta property="og:title"              content="Valérie Volral - Psychologue Clinicienne à Colfontaine" />
                <meta property="og:description"        content="Enfants, Ados, Adultes et Famille - Déprime, Trouble Anxieux, Burnout - Contactez le 065/67 43 14" />
                <meta property="og:image"              content="https://valerievolralpsy.be/images/facebook_og_card.jpg"/>

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Valérie Volral - Psychologue Clinicienne à Colfontaine" />
                <meta name="twitter:description" content="Enfants, Ados, Adultes et Famille - Déprime, Trouble Anxieux, Burnout - Contactez le 065/67 43 14" />
                <meta name="twitter:image" content="https://valerievolralpsy.be/images/twitter_card.jpg" />
                <meta name="twitter:url" content="https://valerievolralpsy.be" />

                <meta http-equiv="Cache-Control" content="must-revalidate, max-age=0, public"/>
                <meta http-equiv="Expires" content="-1"/>
                <meta name="revisit-after" content="15 days"/>

                <meta NAME="Title" CONTENT="Valérie Volral | Psychologue, Clinicien, Enfant, Adulte, Thérapie, Psy, Colfontaine, Wasmes, Borinage, Psychologie, Adolescent, Warquignies, Paturages, Hornu, Dour, Boussu, Frameries, Mons, Saint, Ghislain, Quaregnon, Hainaut"/>
                <meta NAME="Keywords" CONTENT="VALERIE VOLRAL, Clinicien, Enfant, Adulte, Thérapie, Psy, Colfontaine, Wasmes, Borinage, Psychologie, Adolescent, Warquignies, Paturages, Hornu, Dour, Boussu, Frameries, Mons, Saint, Ghislain, Quaregnon, Hainaut"/>

                <link rel="preconnect" href="https://fonts.googleapis.com" crossorigin/>
                <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin/>

                <link
                    rel="preload"
                    href="https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;300&display=swap"
                    as="style"
                    onload="this.onload=null;this.rel='stylesheet'"
                />
                <link
                    rel="preload"
                    href="https://fonts.googleapis.com/css2?family=Katibeh&display=swapp"
                    as="style"
                    onload="this.onload=null;this.rel='stylesheet'"
                />
            </Helmet>

            <NavBar/>

            {/* A Phantom Div to push back the body */}
            <div style={phantomDivStyle}></div>

            <Section id="who-section" className={'background-a'}>
                <WhoSection/>
            </Section>

            <Section id="when-section" className={'background-b'}>
                <WhenSection/>
            </Section>

            <Section id="quote-section" className={'background-a'}>
                <QuoteSection/>
            </Section>

            <Section id="pricing-section" className={'background-b'}>
                <PricingSection/>
            </Section>

            <Section id="contact-section" className={'background-a'}>
                <ContactSection/>
            </Section>

            <Section id="other-informations-section" className={'background-b'}>
                <h2>Autres informations</h2>
                <OtherInformationsSection/>
            </Section>

            <Footer/>
        </main>
    )
}

export default IndexPage
